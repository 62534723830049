<template>
  <div>
    <BaseNav></BaseNav>
    <Layout1Component></Layout1Component>
  </div>
</template>

<script>
import Layout1Component from "../../components/orderforms/Layout1Component.vue";
export default {
  components: { Layout1Component },
};
</script>

<style>
</style>